import { device } from 'src/styles/breakpoints'
import { grayscale, orange } from 'src/styles/colors'
import styled from 'styled-components'

import BannerMobile from '../../assets/Personas.svg'

export const ContentHeader = styled.section`
  padding-top: 50px;
  padding-bottom: 47px;
  background: url(${BannerMobile}) no-repeat top 90px center;

  @media ${device.tablet} {
  background: url(${BannerMobile}) no-repeat top 90px left;
  }
  @media ${device.desktopLG} {
    padding-top: 90px;
  background: url(${BannerMobile}) no-repeat top 90px left;
  }
`

export const Tablet = styled.div`
  @media ${device.tablet} {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    margin-bottom: 22px;
  }
`

export const BackToHome = styled.a`
  display: block;
  text-decoration: underline;
  text-align: left;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 288px;

  svg {
    margin-right: 5px;
  }

  @media ${device.tablet} {
    margin-bottom: 27px;
  }
`

export const Title = styled.span`
  display: block;
  font-family: "Sora", Helvetica, sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: ${grayscale[500]};
  margin-bottom: 16px;

  @media ${device.tablet} {
    font-size: 32px;
    line-height: 40px;
  }
`
export const WeCanHelp = styled.span`
  display: block;
  font-family: "Sora", Helvetica, sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: ${grayscale[500]};
  margin-bottom: 32px;
  padding-left: 11px;
`

export const Description = styled.span`
  display: block;
  font-size: 18px;
  line-height: 22px;
  color: ${grayscale[400]};
  margin-bottom: 40px;
`
export const Form = styled.form`
`

export const InputWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 479px;
  margin-bottom: 0;
  margin-top: 0;

  > div {
    position: absolute;
    right: 10px;

    display: flex;
    gap: 16px;

    z-index: 3;
  }
`

export const Input = styled.input`
  width: 100%;
  background: ${grayscale[100]};
  caret-color: ${orange.extra};
  border-radius: 8px;
  border: none;
  height: 48px;
  padding: 16px 18px;

  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: ${grayscale[500]};

  z-index:2;

  &:active, &:focus {
    border: none;
    outline: none;
  }

  &::placeholder {
    color: #B6B7BB;
  }

`
