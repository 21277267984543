import { device } from 'src/styles/breakpoints'
import { grayscale, orange } from 'src/styles/colors'
import styled from 'styled-components'

export const ContentHeader = styled.section`
  padding-bottom: 40px;

  @media ${device.tablet} {
    padding-top: 20px;
    padding-bottom: 80px;
  }
  @media ${device.desktopLG} {
    padding-top: 80px;
  }
`

export const Title = styled.span`
  display: block;
  margin-bottom: 24px;
  font-size: 16px;
  line-height: 19px;
  color: ${grayscale[400]};
`

export const SearchParam = styled.span`
  display: block;
  margin-bottom: 34px;
  font-family: "Sora", Helvetica, sans-serif;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  color: ${grayscale[500]};
`

export const CardWrapper = styled.div`
  margin-bottom: 24px;
`

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  border: 1px solid ${grayscale[200]};
  border-radius: 8px;
  height: 100%;
`

export const CardTitle = styled.span`
  display: block;
  margin-bottom: 8px;
  font-family: "Sora", Helvetica, sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${grayscale[500]};
`

export const CardLink = styled.a`
  display: block;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: ${orange.extra};

  & + & {
    margin-top: 8px;
  }
`
